import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import EmployeeSalaryList from "./EmployeeSalaryList";
import { getAllEmployee } from "../../../actions/employeeAction";

import { DatePicker, Select, TreeSelect, Col, Space, Row, Button, Spin } from "antd";
import moment from "moment";
import Excelldownload from "./Excelldownload";
import ReactToPrint from "react-to-print";
import PdfDownload from "./PdfDownload";
const Maincontent = ({ getAllEmployee, employeeList }) => {

  const dateFormat = "YYYY-MM-DD";
  var date = new Date();

  const { Option } = Select;
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  var todayDate = moment(date).format(dateFormat);
  let today = year + "-" + month;


  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const fromMonth = useRef(todayDate);
  const employee = useRef("");
  const [data, setData] = useState([]);
  const componentRef = useRef();

  // useEffect(() => {
  //   getAllEmployee();
  // }, []);


  const onFromMonthChange = (value, dateString) => {
    const myArr = dateString.split("-");
    let year = myArr[0];
    let mnth = myArr[1];
    let date = myArr[2];
    // console.log(value)
    fromMonth.current = year + "-" + mnth + "-" + date;
  };

  const onEmployeeChange = (value) => {
    employee.current = value;
  };


  return (
    <>
      <Row wrap={false}>
        <Col flex="auto">
          Month : {" "}
          <DatePicker
            style={{ width: '100%' }}
            picker="Date"
            defaultValue={moment()}
            onChange={onFromMonthChange}
          />
        </Col>
        {/* <Col flex="auto">

          Employee :
          <Select
            showSearch
            placeholder="Please select a Employee"
            style={{ width: "100%" }}
            onChange={(value) => onEmployeeChange(value)}
            optionFilterProp="children"
          >
            <Option value="" select>
              All
            </Option>
            {employeeList.map((employee) => {
              return <Option value={employee.id}>{employee.employee_pin}</Option>;
            })}
          </Select>

        </Col> */}
        <Col flex="auto" style={{ marginRight: "10px" }}>
          .
          <Button type="primary" style={{ width: "100%", }} onClick={() => {
            setReload(!reload)
          }}>Search</Button>
        </Col>
        <Col flex="auto" style={{ marginRight: "10px" }}>
          Excel :
          <Excelldownload
            data={data}

            from={fromMonth}
            loading={loading}
          />
        </Col>
        <Col flex="auto" >
          .
          <ReactToPrint
            trigger={() => <Button type="primary" style={{ width: "100%" }}>Print this out!</Button>}
            content={() => componentRef.current}
          />
          {/* <PdfDownload
            data={data}
            data1={data}
            componentRef={componentRef}
            from={fromMonth}
          /> */}
        </Col>
      </Row>

      <div className="site-layout-background main-frame">
        <Spin spinning={loading}>
          <EmployeeSalaryList
            from={fromMonth.current}
            employee={employee.current}
            reload={reload}
            data={data}
            setData={setData}
            setReload={setReload}
            setLoading={setLoading}
            loading={loading}
            componentRef={componentRef}
          />
        </Spin>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeList: state.employee.employeelist,
  };
};

export default connect(mapStateToProps, { getAllEmployee })(Maincontent);
