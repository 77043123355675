import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";
import moment from 'moment';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const Excelldownload = ({ data, from, loading }) => {
  // console.log(data);
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  let fileName = 'Salary Summary';

  if (loading) {
    return <Button type="ghost" style={{ width: "100%", marginRight: "10px" }}>....Loading</Button>
  } else
    return (
      {
        title: "Department",
        dataIndex: "department_name",
        key: "department_name",
        // ...this.getColumnSearchProps("employeeDepartment"),
      },
      {
        title: "Gross salary before",
        dataIndex: "total_gross_salary_before",
        key: "total_gross_salary_before",
        align: "right",
        // ...this.getColumnSearchProps("employeeId"),
      },
      {
        title: "Gross salary After",
        dataIndex: "total_gross_salary_after",
        key: "total_gross_salary_after",
        align: "right",
        // ...this.getColumnSearchProps("employeeName"),
      },
      {
        title: "Absent payment",
        dataIndex: "total_absent_payment",
        align: "right",
        key: "total_absent_payment",
        // ...this.getColumnSearchProps("employeeRole"),
      },
      {
        title: "Gross salary after deduction",
        dataIndex: "total_gross_salary_after_deduction",
        align: "right",
        key: "total_gross_salary_after_deduction",
        // ...this.getColumnSearchProps("Basicsalary"),
      },
      {
        title: "Overtime",
        dataIndex: "total_overtime",
        key: "total_overtime",
        align: "right"
      },
      {
        title: "Overtime payment",
        dataIndex: "toal_overtime_payment",
        key: "toal_overtime_payment",
        align: "right"
      },
      {
        title: "Net salary",
        dataIndex: "total_net_salary",
        key: "total_net_salary",
        align: "right"
      },
      <ExcelFile element={<Button type="primary" style={{ width: "100%", marginRight: "10px" }}>Download</Button>} filename={fileName}>
        <ExcelSheet data={data} name='Salary Summary'>
          <ExcelColumn label="Department" value="department_name" />
          <ExcelColumn label="Gross salary before" value="total_gross_salary_before" />
          <ExcelColumn label="Gross salary after" value="total_gross_salary_after" />
          <ExcelColumn label="Absent payment" value="total_absent_payment" />
          <ExcelColumn label="Gross salary after deduction" value="total_gross_salary_after_deduction" />
          <ExcelColumn label="Overtime" value="total_overtime" />
          <ExcelColumn label="Overtime payment" value="toal_overtime_payment" />
          <ExcelColumn label="Net salary" value="total_net_salary" />


        </ExcelSheet>
      </ExcelFile>
    );
};

export default Excelldownload;
