import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getAllEmployee } from "../../../actions/employeeAction";
import { createEmployeeSalary } from "../../../actions/employeeSalaryActions";
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  DatePicker,
  Drawer,
  TreeSelect,
  Button,
  Col,
  Row,
  Select,
  message,
  Divider,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const CreateNewEmployeeLeave = ({
  createEmployeeSalary,
  getAllEmployee,
  employeeList,
  reload,
  setReload,
  details,
  flag
}) => {
  const initial = { remarks: "" };
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";

  const [houseRent, sethouseRent] = useState(0);
  const [monthlySalary, setmonthlySalary] = useState(0);

  const calculatePrice = () => {
    let calculatePrice = 0;
    if (monthlySalary !== null) {
      calculatePrice = ((parseFloat(monthlySalary) * 0.5));
    }
    return calculatePrice;
  };

  useEffect(() => {
    form.setFieldsValue({ medAllowance: 750 });
    form.setFieldsValue({ foodAllowance: 1250 });
    form.setFieldsValue({ convenience: 450 });
    form.setFieldsValue({ dailyAllowance: 0 });
    form.setFieldsValue({ incentive: 0 });
  }, []);

  useEffect(() => {
    form.setFieldsValue({ houseRent: calculatePrice() });
  }, [monthlySalary, form]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values) => {
    values.employee = details.id
    createEmployeeSalary(values).then(res => {
      flag.current = 1;
      form.resetFields();
      setReload(!reload);
      setVisible(false);
    });
  };

  return (
    <>
      <>
        <Button
          type="text"
          onClick={showDrawer}
          style={{ marginBottom: "10px", float: "right" }}
        >
          <PlusOutlined /> Add Salary
        </Button>
        <Drawer
          title={details.name}
          width={400}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initial}
          >

            <h1>Name: {details.name}</h1>
            <h3>Department : {details.employeeDepartment}</h3>
            <h3>Designation: {details.user_roleName}</h3>
            <h3>EPIN: {details.employee_pin}</h3>

            <Divider />

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="grossSalary"
                  label="Gross Salary"
                  rules={[
                    { required: true, message: "Please enter Gross Salary" },
                  ]}
                >
                  <Input placeholder="Please enter Gross Salary"
                    onChange={(e) => setmonthlySalary(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="bankAccount"
                  label="Bank Account"
                  rules={[
                    { required: true, message: "Please enter Bank Account" },
                  ]}
                >
                  <Input placeholder="Please enter Bank Account" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="medAllowance"
                  label="Medical"
                  rules={[
                    { required: false },
                  ]}
                >
                  <Input placeholder="Please enter Medical Allowance" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="foodAllowance"
                  label="Food"
                  rules={[
                    { required: false },
                  ]}
                >
                  <Input placeholder="Please enter Food Allowance" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="convenience"
                  label="Convenience"
                  rules={[
                    { required: false },
                  ]}
                >
                  <Input placeholder="Please enter Convenience" />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="houseRent"
                  label="House Rent"
                  rules={[
                    { required: true, message: "Please enter House Rent" },
                  ]}
                >
                  <Input placeholder="Please enter House Rent" initialValue={calculatePrice()} />
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="holidayAllowance"
                  label="Holiday Allowance"
                  rules={[
                    { required: false, message: "Please enter Holiday Allowance" },
                  ]}
                >
                  <Input placeholder="Please enter Holiday Allowance" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="attendanceBonus"
                  label="Attendance Bonus"
                  rules={[
                    { required: false, message: "Please enter Attendance Bonus" },
                  ]}
                >
                  <Input placeholder="Please enter Attendance Bonus" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeList: state.employee.employeelist,
  };
};

export default connect(mapStateToProps, {
  createEmployeeSalary,
  getAllEmployee,
})(CreateNewEmployeeLeave);
