import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import EmployeeList from "./EmployeeList";
import CreateNewEmployee from "./CreateNewEmployee";
import Excelldownload from "./Excelldownload";
import { getAllLocation, getAllLocationPlain } from "../../../actions/warehouseAction";
import { getAllDepartment } from "../../../actions/departmentActions";
import { getAllUserRole } from "../../../actions/userRoleAction";
import { syncAllEmployeebyPagination, syncAllResignedEmployeebyPagination } from "../../../actions/employeeAction";
import { DatePicker, Select, TreeSelect, Col, Space, Row, Spin, Divider, AutoComplete, Button } from "antd";

const { Option } = Select;

const Maincontent = ({ auth, getAllLocationPlain, syncAllEmployeebyPagination, syncAllResignedEmployeebyPagination, locationList, getAllDepartment, departmentList, getAllUserRole, RoleList }) => {
  const [updatelist, setUpdatelist] = useState(true);
  const [reload, setReload] = useState(true);
  const pageno = useRef(1);
  const page_size = useRef(10);
  const location = useRef("")
  const Designation = useRef("")
  const Department = useRef("")
  const keyword = useRef("")
  const flag = useRef(false)
  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);
  const [employeeloading, setEmployeeLoading] = useState(false);
  const [resignedemployeeloading, setResignedEmployeeLoading] = useState(false);
  const progress = useRef(0)

  useEffect(() => {
    getAllLocationPlain();
    getAllDepartment();
    getAllUserRole();
  }, []);

  const callbackfuncation = (i) => {
    syncAllEmployeebyPagination(i, 10).then((e) => {
      if (e.next !== null) {
        i = i + 1;
        progress.current = i;
        callbackfuncation(i)
      } else {
        setEmployeeLoading(false)
      }
    })
  }

  const callbackfuncationforResigned = (i) => {
    syncAllResignedEmployeebyPagination(i, 10).then((e) => {
      if (e.next !== null) {
        i = i + 1;
        // progress.current = i;
        callbackfuncationforResigned(i)
      } else {
        setResignedEmployeeLoading(false)
      }
    })
  }

  const syncdata = () => {
    setEmployeeLoading(true)
    progress.current = 1;
    callbackfuncation(1);
  }

  const syncresigneddata = () => {
    setResignedEmployeeLoading(true)
    progress.current = 1;
    callbackfuncationforResigned(1);
  }

  return (
    <>
      <div className="site-layout-background main-frame">
        <Button style={{ marginRight: "10px" }} onClick={syncdata} >{employeeloading ? "loading...." : "Sync employee data"}</Button>
        <Button onClick={syncresigneddata} >{resignedemployeeloading ? "loading...." : "Sync resigned employee data"}</Button>

        <Divider />
        <Row wrap={false}>
          <Col flex="auto">
            keyword :
            <AutoComplete
              placeholder="EPIN / Name / Phone"
              // onChange={onChange}
              onChange={(value) => {
                keyword.current = value;
              }}
              style={{ width: "100%", minWidth: "140px" }}
            />
          </Col>
          <Col flex="auto">
            Area :
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please select a area"
              // defaultValue={location.current}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              onChange={(value) => {
                location.current = value
                // setReload(!reload);
              }}
              style={{ width: "100%", }}
            >
              <Option value="">All</Option>
              {locationList.map(warehouse => {
                return <Option value={warehouse.id}>{warehouse.name}</Option>;
              })}

            </Select>
          </Col>
          <Col flex="auto">
            Department:
            <Select
              placeholder="Please select a department"
              style={{ width: "100%" }}
              onChange={(e) => {
                Department.current = e;
              }}
            >
              <Option value="">All</Option>
              {departmentList.map((department) => {
                return <Option value={department.id}>{department.name}</Option>;
              })}
            </Select>
          </Col>
          <Col flex="auto">
            Designation:
            <Select
              placeholder="Please select a designation"
              style={{ width: "100%" }}
              onChange={(e) => {
                Designation.current = e;
              }}
            >
              <Option value="">All</Option>
              {RoleList.map((role) => {
                return <Option value={role.id}>{role.name}</Option>;
              })}
            </Select>
          </Col>
          <Col flex="auto">
            .
            <Button style={{ width: "100%" }} onClick={() => {
              flag.current = true;
              setReload(!reload)
            }}>Search</Button>
          </Col>
          {auth.permissions.includes("HRM.Employee_is_create") ? (
            <Col flex="auto">
              .
              <CreateNewEmployee
                setUpdatelist={setUpdatelist}
                updatelist={updatelist}
              />
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Divider />

        <Spin spinning={loading}>
          <EmployeeList
            loading={loading}
            setloading={setloading}
            location={location}
            Designation={Designation}
            Department={Department}
            Keyword={keyword}
            reload={reload}
            flag={flag}
            setReload={setReload}
            data={data}
            setData={setData}
            pageno={pageno}
            page_size={page_size}
          />
        </Spin>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locationList: state.warehouse.locationlist,
    departmentList: state.department.departmentlist,
    RoleList: state.userRole.userRolelist,
  };
};

export default connect(mapStateToProps, { getAllLocationPlain, getAllDepartment, getAllUserRole, syncAllEmployeebyPagination, syncAllResignedEmployeebyPagination })(Maincontent);
