import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";
import moment from 'moment';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const Excelldownload = ({ data, from, loading }) => {
  // console.log(data);
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  let fileName = 'Overtime Summary';

  if (loading) {
    return <Button type="ghost" style={{ width: "100%", marginRight: "10px" }}>....Loading</Button>
  } else
    return (


      <ExcelFile element={<Button type="primary" style={{ width: "100%", marginRight: "10px" }}>Download</Button>} filename={fileName}>
        <ExcelSheet data={data} name='Overtime Summary'>
          <ExcelColumn label="Department" value="department_name" />
          <ExcelColumn label="Total OT hours" value="total_overtimenextraovertime" />
          <ExcelColumn label="Deduction OT hour" value="total_overtime" />
          <ExcelColumn label="Payable OT hour" value="total_extraovertime" />
          <ExcelColumn label="Payable OT Amount" value="total_overtimepayment" />



        </ExcelSheet>
      </ExcelFile>
    );
};

export default Excelldownload;
