import React, { useState } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { updateDepartment } from "../../../actions/departmentActions";
import {
  Form,
  Input,
  Drawer,
  Button,
  Col,
  Row,
  Select,
  TreeSelect,
} from "antd";
import { getAllLocation } from "../../../actions/warehouseAction";
const { Option } = Select;

const Edit = ({
  details,
  setUpdatelist,
  updateDepartment,
  Locations,
  getAllLocation,
}) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    updateDepartment(details.id, values);
    setUpdatelist(false);
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        Edit
      </Button>

      <Drawer
        title="Edit Department"
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} initialValues={details}>
          <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                  name="code"
                  label="Department Code"
                  rules={[
                    { required: true, message: "Please enter department code" },
                  ]}
                >
                  <Input placeholder="Please enter department name" />
                </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please enter user name" }]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Locations: state.warehouse.locationlist,
  };
};

export default connect(mapStateToProps, { updateDepartment, getAllLocation })(
  Edit
);
