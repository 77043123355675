import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSpecificUserEmployeeLeave,
  getAllEmployeeLeave,
} from "../../../actions/employeeLeaveActions";
import {
  getSpecificEmployeeAttendence,
  getAllEmployeeAttendenceOfMonth,
  getAllEmployeeAttendenceOfDateRange,
  getEmployeelateReport,
  syncAttendenceByDate,
  syncAbsentByDate,
} from "../../../actions/AttendenceAction";
import { getAllEmployeeByBranch } from "../../../actions/employeeAction";
import moment from "moment";

import { Row, Col, Skeleton } from "antd";

const EmployeeAttendanceList = ({
  getEmployeelateReport,
  syncAttendenceByDate,
  syncAbsentByDate,
  employee,
  location,
  startDate,
  endDate,
  data,
  setData,
  updatelist,
  setUpdatelist,
  componentRef,
}) => {
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setloading(true);
    syncAttendenceByDate(startDate, startDate, "1,2,3,4").then(e => {
      syncAbsentByDate(startDate, startDate, "1,2,3,4").then(e => {
        getEmployeelateReport(startDate, endDate, location).then(e => {
          setData(e)
          setloading(false);
        })
      })
    })
    // getEmployeelateReport(startDate, endDate, location).then(e => {
    //   setData(e)
    //   setloading(false);
    // })


  }, [updatelist, startDate, endDate, employee, location, setUpdatelist]);



  if (loading) {
    return <Skeleton active />;
  } else {
    return (
      <>
        <div ref={componentRef}>
          <table className="leave_table" style={{ borderBottom: "0px", lineHeight: "14px", }} >
            <tr><td><h3 style={{ fontWeight: 500, fontSize: "22px", paddingTop: "10px" }}>Famous Design LTD.</h3>
              <p>245/1-F,West Agargoan, Sher-E-Bangla nagar,Dhaka-1207.</p>
              <p style={{ fontWeight: 500, fontSize: "20px", textDecoration: "underline" }}>Late Report </p>
              <p>Date : {moment(startDate).format("DD-MM-YYYY")}</p>
            </td></tr>
          </table>

          <table className="leave_table" >

            <tr style={{ fontWeight: 700 }}>
              <td>PIN</td>
              <td >Name</td>
              <td >Department</td>
              <td >Designation</td>
              <td >Late</td>

            </tr>
            {data.attendanceData.map(late => {
              return <>
                <tr>
                  <td>{late.EPIN}</td>
                  <td>{late.employee}</td>
                  <td>{late.department}</td>
                  <td>{late.designation}</td>
                  <td>{late.late}</td>

                </tr>
              </>
            })}
          </table >
        </div>
      </>
    );
  }
};



export default connect(null, {
  getSpecificUserEmployeeLeave,
  getSpecificEmployeeAttendence,
  getAllEmployeeAttendenceOfMonth,
  getAllEmployeeAttendenceOfDateRange,
  getEmployeelateReport,
  getAllEmployeeLeave,
  getAllEmployeeByBranch,
  syncAttendenceByDate,
  syncAbsentByDate,
})(EmployeeAttendanceList);
