import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import EmployeeList from "./EmployeeList";
import { getAllLocation, getAllLocationPlain } from "../../../actions/warehouseAction";
import { getAllNewEmployeeByBranch } from "../../../actions/employeeAction";
import { getAllDepartment } from "../../../actions/departmentActions";
import { getAllUserRole } from "../../../actions/userRoleAction";
import { DatePicker, Select, TreeSelect, Col, Space, Row, Spin, Divider, AutoComplete, Button } from "antd";
import Excelldownload from "./Excelldownload";
import EmplooyeeImport from "./EmplooyeeImport";

const { Option } = Select;

const Maincontent = ({ auth, getAllLocationPlain, locationList, getAllDepartment, departmentList, getAllUserRole, RoleList, getAllNewEmployeeByBranch }) => {
  const [reload, setReload] = useState(true);
  const pageno = useRef(1);
  const page_size = useRef(10);
  const location = useRef("")
  const Designation = useRef("")
  const Department = useRef("")
  const keyword = useRef("")
  const flag = useRef(false)
  const [loading, setloading] = useState(false);
  const [excelloading, setExcelloading] = useState(true);
  const [exceldata, setExceldata] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllLocationPlain();
    getAllDepartment();
    getAllUserRole();

    getAllNewEmployeeByBranch("", "", "", "", 1, 100000).then(e => {
      setExceldata(e)
      console.table(e)
      setExcelloading(false)
    })
  }, []);

  return (
    <>
      <div className="site-layout-background main-frame">
        {/* <EmplooyeeImport /> */}
        <Row wrap={false}>
          <Col flex="auto">
            keyword :
            <AutoComplete
              placeholder="EPIN / Name / Phone"
              // onChange={onChange}
              onChange={(value) => {
                keyword.current = value;
              }}
              style={{ width: "100%", minWidth: "140px" }}
            />
          </Col>
          <Col flex="auto">
            Area :
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please select a area"
              // defaultValue={location.current}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              onChange={(value) => {
                location.current = value
                // setReload(!reload);
              }}
              style={{ width: "100%", }}
            >
              <Option value="">All</Option>
              {locationList.map(warehouse => {
                return <Option value={warehouse.id}>{warehouse.name}</Option>;
              })}

            </Select>
          </Col>
          <Col flex="auto">
            Department:
            <Select
              placeholder="Please select a department"
              style={{ width: "100%" }}
              onChange={(e) => {
                Department.current = e;
              }}
            >
              <Option value="">All</Option>
              {departmentList.map((department) => {
                return <Option value={department.id}>{department.name}</Option>;
              })}
            </Select>
          </Col>
          <Col flex="auto">
            Designation:
            <Select
              placeholder="Please select a designation"
              style={{ width: "100%" }}
              onChange={(e) => {
                Designation.current = e;
              }}
            >
              <Option value="">All</Option>
              {RoleList.map((role) => {
                return <Option value={role.id}>{role.name}</Option>;
              })}
            </Select>
          </Col>
          <Col flex="auto">
            .
            <Button style={{ width: "100%" }} onClick={() => {
              flag.current = true;
              setReload(!reload)
            }}>Search</Button>
          </Col>
          <Col flex="auto">
            .
            <Excelldownload data={exceldata} excelloading={excelloading} />
          </Col>
        </Row>
        <Divider />

        <Spin spinning={loading}>
          <EmployeeList
            loading={loading}
            setloading={setloading}
            location={location}
            Designation={Designation}
            Department={Department}
            Keyword={keyword}
            reload={reload}
            flag={flag}
            setReload={setReload}
            data={data}
            setData={setData}
            pageno={pageno}
            page_size={page_size}
          />
        </Spin>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locationList: state.warehouse.locationlist,
    departmentList: state.department.departmentlist,
    RoleList: state.userRole.userRolelist,
  };
};

export default connect(mapStateToProps, { getAllLocationPlain, getAllDepartment, getAllUserRole, getAllNewEmployeeByBranch })(Maincontent);
