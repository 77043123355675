import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllNewEmployeeByBranch } from "../../../actions/employeeAction";
import Rendertable from "./Rendertable";
import { getAllLocation } from "../../../actions/warehouseAction";
import { getAllUserRole } from "../../../actions/userRoleAction";

const EmployeeList = ({
  getAllNewEmployeeByBranch,
  employeeList,
  reload,
  setReload,
  setData,
  loading,
  setloading,
  location,
  Designation,
  Department,
  Keyword,
  flag,
  data,
  pageno,
  page_size,
}) => {
  useEffect(() => {
    // if (flag.current) {
    setloading(true);
    getAllNewEmployeeByBranch(location.current, Designation.current, Department.current, Keyword.current, pageno.current, page_size.current).then((e) => {
      setData(e);
      setloading(false);

    });
    // }

  }, [reload]);

  return (
    <>
      {
        <Rendertable
          loading={loading}
          List={data}
          reload={reload}
          setReload={setReload}
          pageno={pageno}
          page_size={page_size}
          flag={flag}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeList: state.employee.employeelistByBranchShift,
  };
};

export default connect(mapStateToProps, {
  getAllNewEmployeeByBranch,
})(EmployeeList);
