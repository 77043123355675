import React, { useState } from "react";
import { connect } from "react-redux";
import DepartmentList from "./DepartmentList";
import CreateNewDepartment from "./CreateNewDepartment";
import DepartmentTemplateDownload from "./DepartmentTemplateDownload";
import { DatePicker, Select, TreeSelect, Col, Row, Button, Spin } from "antd";
const Maincontent = ({auth}) => {
  const [updatelist, setUpdatelist] = useState(true);
  const [data, setData] = useState([]);

  return (
    <>
      <Row wrap={false}>
        <Col flex="auto">
          {/* Import Department: */}
          <Button
            // onClick={() => history.push("/import-attendance")}
            onClick={() => (window.location.href = "/import-department")}
            style={{ width: "100%",}}
          >
            Department Data! 
          </Button>
        </Col>
        <Col flex="auto">
          {/* Download Template: */}
          <DepartmentTemplateDownload data={data} data1={data} />
        </Col>
      </Row>
      <div className="site-layout-background main-frame">
     { auth.permissions.includes("HRM.Departments_is_create")
            ?  <CreateNewDepartment
          setUpdatelist={setUpdatelist}
          updatelist={updatelist}
        />:""}
        <DepartmentList updatelist={updatelist} setUpdatelist={setUpdatelist} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Maincontent);
