import React, { useEffect, useState, useRef } from "react";
import { Button } from "antd";
import ReactExport from "react-export-excel";
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
    name: "Johson",
    amount: 30000,
    sex: "M",
    is_married: true,
  },
  {
    name: "Monika",
    amount: 355000,
    sex: "F",
    is_married: false,
  },
  {
    name: "John",
    amount: 250000,
    sex: "M",
    is_married: false,
  },
  {
    name: "Josef",
    amount: 450500,
    sex: "M",
    is_married: true,
  },
];

var dataSet2 = [
  {
    name: "Johnson",
    total: 25,
    remainig: 16,
  },
  {
    name: "Josef",
    total: 25,
    remainig: 7,
  },
];

const Excelldownload = ({ data, data1, startDate, endDate }) => {
  // console.log(data);
  let fileName = "Absent Report";
  return (
    <ExcelFile
      element={<Button style={{ width: "100%" }}>Download</Button>}
      filename={fileName}
    >
      <ExcelSheet data={data.attendanceData} name="Absent Report">
        <ExcelColumn label="PIN" value="EPIN" />
        <ExcelColumn label="Name" value="employee" />
        <ExcelColumn label="Department" value="department" />
        <ExcelColumn label="Designation" value="designation" />
        {/* <ExcelColumn label="Late" value="late" /> */}

      </ExcelSheet>

    </ExcelFile>
  );
};

export default Excelldownload;
