import axios from "axios";

let baseUrl;
const bioTimeBaseUrl = "http://103.160.93.205:83/";

if (window.location.origin === "http://localhost:3000") {
  baseUrl = "http://127.0.0.1:8000/";

} else {
  //production
  // baseUrl = process.env.REACT_APP_API_URL;
  baseUrl = "https://famousapi.up.railway.app/";
}
export default axios.create({
  baseURL: baseUrl,
});
