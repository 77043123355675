import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getAllEmployee, getEmployeeReport } from "../../../actions/employeeAction";
import { createEmployeePaySlip } from "../../../actions/PaySlipAction";
import moment from "moment";

import { Layout, Breadcrumb, Row, Col, Select, Divider, DatePicker, Button, InputNumber } from "antd";
const { Content } = Layout;
const { Option } = Select;

const AddPayslip = ({ getAllEmployee, employeeList, getEmployeeReport, createEmployeePaySlip }) => {
    const [updatelist, setUpdatelist] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const selectedEmployee = useRef(null)
    const DaysInMonth = useRef("")
    const Month = useRef(1)
    const Year = useRef(2023)
    const NoOfHolidays = useRef(0)
    const NoOfWokingDays = useRef(0)
    const Overtime = useRef(0)
    const Extraovertime = useRef(0)
    const holidayAllowance = useRef(0)
    const holidayattendance = useRef(0)
    const attendancebouns = useRef(0)

    useEffect(() => {
        getAllEmployee();
    }, []);

    useEffect(() => {
        if (selectedEmployee.current != null && DaysInMonth.current != "") {
            setLoading(true);
            getEmployeeReport(selectedEmployee.current, Month.current, Year.current, DaysInMonth.current, NoOfHolidays.current, NoOfWokingDays.current, Overtime.current, Extraovertime.current, holidayAllowance.current, holidayattendance.current, attendancebouns.current).then(data => {
                console.log(data);
                setData(data)
                setLoading(false);
            });
        }
    }, [reload]);

    const onFinish = (method, heldup) => {
        console.log(data.employee)
        console.log(JSON.stringify(data.employee))
        const formData = new FormData();
        formData.append("employee", data.employee.id);
        formData.append("salary", data.salary.id);
        formData.append("salaryMonth", Month.current);
        formData.append("salaryYear", Year.current);
        formData.append("publicHoliday", NoOfHolidays.current);
        formData.append("payment_method_info_1", method);
        formData.append("heldup", heldup);
        formData.append("amount_1", data.summaryData.totalsalary);
        formData.append("employeeData", JSON.stringify(data.employee));
        formData.append("attendanceData", JSON.stringify(data.attendance));
        formData.append("leaveData", JSON.stringify(data.leave));
        formData.append("salaryData", JSON.stringify(data.salary));
        formData.append("summaryData", JSON.stringify(data.summaryData));
        createEmployeePaySlip(formData).then(e => {
            alert("Paid")
        })

    }

    var days = function (month, year) {
        return new Date(year, month, 0).getDate();
    };

    const onMonthChange = (value, dateString) => {
        const myArr = dateString.split("-");
        let year = myArr[0];
        let mnth = myArr[1];
        Month.current = mnth
        Year.current = year
        DaysInMonth.current = days(mnth, year)
    };
    const onFilter = () => {
        if (selectedEmployee.current == null) {
            alert("Please select an Employee")
            return
        }
        if (DaysInMonth.current == "") {
            alert("Please select a month")
            return
        }
        setReload(!reload)
    }

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>PaySlip</Breadcrumb.Item>
                <Breadcrumb.Item>Add</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-background main-frame">
                <Row wrap={false}>
                    <Col flex="auto">
                        EPIN :
                        {/* <Select
                            showSearch
                            placeholder="Please choose an Employee"
                            style={{ fontWeight: "400", width: "100%", minWidth: "140px" }}
                            optionFilterProp="children"
                            onChange={(value) => selectedEmployee.current = value}
                        // filterOption={(input, option) =>
                        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                        //     0
                        // }
                        >
                            {employeeList.map((employee) => {
                                return <Option value={employee.id}>{employee.employee_pin}</Option>;
                            })}
                        </Select> */}

                        <InputNumber
                            placeholder="Employee PIN"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                selectedEmployee.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Month :
                        <DatePicker
                            picker="month"
                            style={{ width: "100%", minWidth: "140px" }}
                            // defaultValue={moment()}
                            onChange={onMonthChange}
                            bordered={true}
                        />
                    </Col>
                    <Col flex="auto">
                        No of Holidays :
                        <InputNumber
                            placeholder="No of Holidays"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                NoOfHolidays.current = value;
                            }}
                            // defaultValue={0}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        No of Working days :
                        <InputNumber
                            placeholder="No of Working days"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                NoOfWokingDays.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Overtime :
                        <InputNumber
                            placeholder="Overtime"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                Overtime.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Extra overtime :
                        <InputNumber
                            placeholder="Extra overtime"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                Extraovertime.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Holiday Allowance :
                        <InputNumber
                            placeholder="Holiday Allowance"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                holidayAllowance.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Holiday Attendance :
                        <InputNumber
                            placeholder="Holiday Attendance"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                holidayattendance.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Attendance bonus:
                        <InputNumber
                            placeholder="Attendance bonus"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                attendancebouns.current = value;
                            }}
                            min={0}
                        />
                    </Col>


                    <Col flex="auto">
                        .
                        <Button style={{ width: "100%", minWidth: "140px" }} type="primary" onClick={onFilter}>
                            Filter
                        </Button>
                    </Col>
                </Row>
                {data ?
                    data.error == 0 ?
                        <>
                            <Divider />
                            <Row>
                                <table style={{ width: "100%", border: "1px solid gray" }}>
                                    <tr >
                                        <td style={{ width: "100%", padding: "10px" }}>
                                            <h3>Famous Design LTD.</h3>
                                            <p>245/1-F,West Agargoan, Sher-E-Bangla nagar,Dhaka-1207.</p>
                                            <p>বেতন ও অতিরিক্ত কাজের সময় রশিদ </p>
                                            <p>মাসের নাম ও বছর : {moment(Year.current + "-" + Month.current + "-1").format("MMMM,  YYYY")}</p>
                                        </td>
                                    </tr>
                                </table>
                                <table style={{ width: "100%", border: "1px solid gray" }}>
                                    <tr >
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            কার্ড নং<br />
                                            নাম<br />
                                            পদবি<br />
                                            বিভাগ  <br />
                                            যোগদান   <br />
                                            বেতন বৃদ্ধি  <br />

                                        </td>
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            : {data.employee.employee_pin}<br />
                                            : {data.employee.name} <br />
                                            : {data.employee.user_roleName}<br />
                                            : {data.employee.employeeDepartment} <br />
                                            : {moment(data.employee.joining_date).format("DD-MM-YYYY")}<br />
                                            : <br />

                                        </td>
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            মাসের দিন<br />
                                            কাজের দিন<br />
                                            সাপ্তাহিক ছুটি<br />
                                            উপস্থিত <br />
                                            অনুপস্থিত <br />
                                            {/* অনুপস্থিত <br /> */}
                                            {/* সবেতন ছুটি <br /> */}

                                        </td>
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            : {data.attendance.totaldays}<br />
                                            : {data.attendance.workingdays}<br />
                                            : {data.attendance.Holidays} <br />
                                            : {data.attendance.present} <br />
                                            : {data.attendance.absent} <br />
                                            {/* : {data.attendance.paidleave} <br /> */}

                                        </td>

                                        <td style={{ width: "10%", padding: "10px" }}>
                                            ছুটির কারণ<br />
                                            {data.leave.map(item => {
                                                return <>{item.leaveType__Typename}<br /></>
                                            })}


                                        </td>
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            <br />
                                            {data.leave.map(item => {
                                                return <>{item.total}<br /></>
                                            })}


                                        </td>
                                        <td style={{ width: "10%", padding: "10px" }}></td>
                                        <td style={{ width: "10%", padding: "10px" }}></td>
                                    </tr>
                                </table>
                                <table style={{ width: "100%", border: "1px solid gray" }}>
                                    <tr >
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            মূল বেতন  <br />
                                            বাড়ি ভাড়া  <br />
                                            চিকিৎসা ভাতা  <br />
                                            খাবার ভাতা  <br />
                                            অন্যান্য ভাতা  <br />
                                            মোট বেতন  <br />
                                        </td>
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            : {data.salary.monthlySalary}<br />
                                            : {data.salary.houseRent}<br />
                                            : {data.salary.medAllowance}<br />
                                            : {data.salary.foodAllowance}<br />
                                            : {data.salary.convenience}<br />
                                            : {data.salary.grossSalary}<br />
                                        </td>
                                        <td style={{ width: "20%", padding: "10px" }}>
                                            অতিরিক্ত কাজের সময় মোট ঘণ্টা    <br />
                                            অতিরিক্ত কাজের সময় রেন্ট / ঘণ্টা   <br />
                                            প্রাপ্য অতিরিক্ত কাজের সময় <br />
                                            মোট পরিশোধ যোগ্য টাকার পরিমাণ <br />

                                            {/* উপস্থিত বোনাস <br />
                                    সর্বমোট বেতনের পরিমাণ <br />
                                    মোট পরিশোধ যোগ্য টাকার পরিমাণ <br /> */}
                                        </td>
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            : {data.attendance.overtime}  <br />
                                            : {data.salary.overtime} <br />
                                            : {data.attendance.extratime} <br />
                                            : {data.attendance.overtimepayment} <br />

                                            {/* উপস্থিত বোনাস <br />
                                    সর্বমোট বেতনের পরিমাণ <br />
                                    মোট পরিশোধ যোগ্য টাকার পরিমাণ <br /> */}
                                        </td>
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            {/* বিনা বেতন ছুটি <br /> */}
                                            অনুপস্থিত কর্তন   <br />
                                            {/* মোট কর্তন <br /> */}

                                        </td>
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            {/* : {data.attendance.paidleavepayment} <br /> */}
                                            : {data.attendance.absentpayment} <br />
                                            {/* মোট কর্তন <br /> */}

                                        </td>
                                        <td style={{ padding: "10px", padding: "10px" }}>
                                            সর্বমোট <br />
                                        </td>
                                        <td style={{ width: "10%", padding: "10px" }}>
                                            {data.summaryData.totalsalary}<br />
                                        </td>
                                    </tr>

                                </table>

                            </Row>
                            <Divider />
                            {data.summaryData.paid ? <><h3>Already Paid</h3><Button type="primary" onClick={() => { onFinish("Bank", false) }}>Resubmit with bank</Button><Button type="primary" onClick={() => { onFinish("Cash", false) }}>Resubmit with cash</Button><Button type="primary" onClick={() => { onFinish("", true) }}>Resubmit with heldup</Button></> : <><Button type="primary" onClick={() => { onFinish("Bank", false) }}>Bank Pay</Button> <Button type="primary" onClick={() => { onFinish("Cash", false) }}>Cash Pay</Button> <Button type="primary" onClick={() => { onFinish("", true) }}>Heldup</Button></>}

                        </>
                        :
                        <>
                            <h1 style={{ marginTop: "20px", color: "red" }}>{data.message}</h1>
                        </>

                    :
                    ""
                }

            </div >
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        employeeList: state.employee.employeelist,
    };
};

export default connect(mapStateToProps, { getAllEmployee, getEmployeeReport, createEmployeePaySlip })(AddPayslip);
