import * as types from "../redux/types";
import backend from "../api/api";
import { store } from "../redux/store";
import { message } from "antd";
import axios from "axios";

const getConfig = () => {
  const token = store.getState().auth.token;

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return config;
};

// const getConfigForBioTime = () => {
//   const token = "522ee89c91b4e930ae93be6703ba5a3193babac6"
//   const url = "http://103.160.93.205:83/";

//   const config = {
//     headers: {
//       // Authorization: `Token ${token}`,
//       "Content-Type": "application/json",
//       // "Authorization": "Token 522ee89c91b4e930ae93be6703ba5a3193babac6",
//       "Authorization": `Token ${token}`,
//       // 'Access-Control-Allow-Origin': "http://localhost:3000",

//     },
//   };
//   return config;
// };



export const createDepartment = (formValues) => async (dispatch) => {
  formValues["data"] = "";
  try {
    const response = await backend.post(
      "api/hrm/Department/",
      { ...formValues },
      getConfig()
    );
    if (response.status === 201) {
      dispatch({
        type: types.CREATE_DEPARTMENT,
        payload: { ...response.data },
      });
      message.success(formValues["name"] + " Created Successfully");
      // history.push("/department");
    }
  } catch (error) {
    alert(error);
  }
};

// export const getAllDepartmentBioTime = () => async () => {
//   try {
//     const bioTimeBaseUrl = "http://103.160.93.205:83/";
//     const authToken = "522ee89c91b4e930ae93be6703ba5a3193babac6";

//     const response = await axios.get(`${bioTimeBaseUrl}personnel/api/departments/`, 
//     {
//       headers: {
//         Authorization: `Token ${authToken}`,
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "http://localhost:3000"

//       },
//     }
//   );

//     if (response.status === 201) {
//       // const newBioTime = biotimeResponse.data
//       // dispatch({
//       //   type: types.GET_ALL_DEPARTMENT,
//       //   payload: response.data
//       // });
//       console.log(response);
//       return response.data;
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getAllDepartment = () => async (dispatch) => {
  try {
    const response = await backend.get("api/hrm/Department/", getConfig());
    console.log(response)
    if (response.status === 200) {
      dispatch({
        type: types.GET_ALL_DEPARTMENT,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteDepartment = (id) => async (dispatch) => {
  try {
    const response = await backend.delete(
      `api/hrm/Department/${id}/`,
      getConfig()
    );
    if (response.status === 204) {
      dispatch({ type: types.DELETE_DEPARTMENT, payload: id });
    }
    // history.push("/department");
  } catch (error) {
    alert(error);
  }
};

export const updateDepartment = (id, formValues) => async (dispatch) => {
  try {
    const response = await backend.patch(
      `api/hrm/Department/${id}/`,
      formValues,
      getConfig()
    );
    if (response.status === 200) {
      dispatch({
        type: types.UPDATE_DEPARTMENT,
        payload: { ...response.data },
      });
    }
    message.success("Updated Successfully");
    // history.push("/department");
  } catch (error) {
    alert(error.response);
  }
};
