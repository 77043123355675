import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excelldownload = ({ data, excelloading }) => {
  if (excelloading) {
    return <Button type="ghost" style={{ width: "100%" }}>Loading Data.....</Button>
  }
  else {
    return (
      <ExcelFile element={<Button type="primary" style={{ width: "100%" }}>Download Data</Button>}>
        <ExcelSheet data={data.results} name="All Invoices">
          <ExcelColumn label="Department" value="employeeDepartment" />
          <ExcelColumn label="EPIN" value="employee_pin" />
          <ExcelColumn label="Name" value="name" />

          <ExcelColumn label="Designation" value="user_roleName" />
          {/* <ExcelColumn label="Area" value="branchName" /> */}
          {/* <ExcelColumn label="Bill" value="bill" /> */}
          <ExcelColumn label="Mobile No." value="phone" />
          <ExcelColumn label="Account No." value="" />
          <ExcelColumn label="Signature" value="" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
};

export default Excelldownload;
