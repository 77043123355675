import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import "./App.css";
import LoginForm from "./components/Templete/LoginForm";
import Frame from "./components/Layout/Frame";
import SignupForm from "./components/Templete/SignupForm";
import Authorization from "./components/Templete/Authorization";

//Contact
import Contact from "./components/Contact/Customer";
import Supplier from "./components/Contact/Supplier";
import ContactOptions from "./components/Contact/Options/index";

//purchase
import Purchase from "./components/Purchase";
import AddPurchaseOrder from "./components/Purchase/add";

// Manufacturing
import Workorder from "./components/Manufacturing/Workorder";
import Productionlines from "./components/Manufacturing/ProductionLine";
import Workstations from "./components/Manufacturing/Workstation";
import WorkstationItem from "./components/Manufacturing/WorkstationItem";

//product
import Attribute from "./components/Product/Attribute/index";
import Category from "./components/Product/Category";
import AddProduct from "./components/Product/AddProduct";
import ProductDetails from "./components/Product/ProductDetails";
import ViewProduct from "./components/Product/ProductDetails/ViewProduct";
import ProductImport from "./components/Product/Options/index";

//Stock
import Stock from "./components/Stock/Stock";
import AlertStock from "./components/Stock/AlertStock";
import Transfer from "./components/Transfer";
import TransferHistory from "./components/Transfer/TransferHistory";
import TransferStock from "./components/Stock/TransferStock/";
import StockValuation from "./components/Stock/StockValuation/";
import Packaging from "./components/Stock/Packaging/";
import Material from "./components/Stock/Material/";

//Order
import Order from "./components/Order";
import AddOrder from "./components/Order/neworder/add";
import CustomerAddOrder from "./components/Order/neworder/add";
import Customerprofile from "./components/Order/neworder";
import Cupon from "./components/Cupon";
import OrderReport from "./components/Order/Report/salesReport";
import Showinvoice from "./components/Order/Showinvoice";
import Services from "./components/Services/Services";
import Invoice from "./components/Order/CustomerInvoice";
import DraftCostSheet from "./components/Order/DraftCostSheet";
import PrintView from "./components/Order/DraftCostSheet/Report/PrintView";

// Tools
import Tools from "./components/Settings/Tools";
import Warehouse from "./components/Settings/Warehouse";
import Outlet from "./components/Settings/Outlet";
import Office from "./components/Settings/Office";
import BusinessProfile from "./components/Settings/BusinessProfile/Index";
import ContactGroup from "./components/Settings/ContactGroup/Index";
import DeliveryGroup from "./components/Settings/DeliveryGroup/Index";
import AccountGroup from "./components/Settings/AccountGroup/Index";
import Permissions from "./components/Settings/Permissions";
import GroupOfCompany from "./components/Settings/GroupOfCompany";
import Company from "./components/Settings/Company";
import OfficeLocation from "./components/Settings/OfficeLocation";
import OfficeType from "./components/Settings/OfficeType";


// HRM
import Department from "./components/HRM/Department";
import DepartmentImport from "./components/HRM/Department/DepartmentImport";
import UserRole from "./components/HRM/UserRoles";
import UserRoleImport from "./components/HRM/UserRoles/UserRoleImport";
import Employee from "./components/HRM/Employee";
import LeaveType from "./components/HRM/LeaveType";
import EmployeeLeave from "./components/HRM/EmployeeLeave";
import LateReport from "./components/HRM/LateReport";
import DepartmentWiseSalaryReport from "./components/HRM/DepartmentwiseSalaryReport";
import EmployeeAttendanceReport from "./components/HRM/AttendenceReport";
import EmployeeSalary from "./components/HRM/Salary";
import NewEmployeeSalary from "./components/HRM/SalaryNewEmployee";
import LoanManagement from "./components/HRM/LoanManagement";
import PaySlip from "./components/HRM/Payslip";
import NewPaySlip from "./components/HRM/Payslip/NewPaySlip";
import AttendanceImport from "./components/HRM/AttendenceOld/AttendanceExcelimport";
import EmployeeSalaryList from "./components/HRM/SalaryReport";
import AttendanceReportShort from "./components/HRM/AttendanceReportShort";
import DailyAttendanceReport from "./components/HRM/DailyAttendanceReport";
import DepartmentWiseAttendanceReport from "./components/HRM/DepartmentWiseAttendanceReport";
import Attendence from "./components/HRM/AttendenceOld";
import OvertimeReport from "./components/HRM/OvertimeReport";
import DepartmentwiseOvertimeReport from "./components/HRM/DepartmentwiseOvertimeReport";
import HolidayAllowance from "./components/HRM/HolidayAllowance";
import HeldupReport from "./components/HRM/HeldupReport";
import SalaryStatement from "./components/HRM/SalaryStatement";
import PresentReport from "./components/HRM/PresentReport";
import AbsentReport from "./components/HRM/AbsentReport";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Frame />}>
          {/* Manufacturing */}


          <Route
            exact
            path="/"
            element={
              <Authorization Module={"pass"} SubModule={"pass"}>
                <Dashboard />
              </Authorization>
            }
          ></Route>

          <Route
            exact
            path="dashboard"
            element={
              <Authorization Module={"pass"} SubModule={"pass"}>
                <Dashboard />
              </Authorization>
            }
          ></Route>

          {/* ---------------------------------------HRM---------------------------------------- */}
          <Route
            exact
            path="/department"
            element={
              <Authorization Module={"HRM"} SubModule={"Departments"}>
                <Department />
              </Authorization>
            }
          />
          <Route
            exact
            path="/import-department"
            element={
              <Authorization Module={"HRM"} SubModule={"Departments"}>
                <DepartmentImport />
              </Authorization>
            }
          />
          <Route
            exact
            path="/user-role"
            element={
              <Authorization Module={"HRM"} SubModule={"Designation"}>
                <UserRole />
              </Authorization>
            }
          />
          <Route
            exact
            path="/import-role"
            element={
              <Authorization Module={"HRM"} SubModule={"Designation"}>
                <UserRoleImport />
              </Authorization>
            }
          />
          <Route
            exact
            path="/employee"
            element={
              <Authorization Module={"HRM"} SubModule={"Employee"}>
                <Employee />
              </Authorization>
            }
          />
          <Route
            exact
            path="/leave-type"
            element={
              <Authorization Module={"HRM"} SubModule={"LeaveType"}>
                <LeaveType />
              </Authorization>
            }
          />
          <Route
            exact
            path="/employee-leave"
            element={
              <Authorization Module={"HRM"} SubModule={"Employee Leave"}>
                <EmployeeLeave />
              </Authorization>
            }
          />
          <Route
            exact
            path="/attendance"
            element={
              <Authorization Module={"HRM"} SubModule={"Attendance"}>
                <Attendence />
              </Authorization>
            }
          />
          <Route
            exact
            path="/attendance-report"
            element={
              <Authorization Module={"HRM"} SubModule={"Attendance List"}>
                <EmployeeAttendanceReport />
              </Authorization>
            }
          />
          <Route
            exact
            path="/newsalary"
            element={
              <Authorization Module={"HRM"} SubModule={"Salary Setup"}>
                <NewEmployeeSalary />
              </Authorization>
            }
          />
          <Route
            exact
            path="/salary"
            element={
              <Authorization Module={"HRM"} SubModule={"Salary Setup"}>
                <EmployeeSalary />
              </Authorization>
            }
          />
          <Route
            exact
            path="/loan-management"
            element={
              <Authorization Module={"HRM"} SubModule={"Loan Management"}>
                <LoanManagement />
              </Authorization>
            }
          />
          <Route
            exact
            path="/pay-slip"
            element={
              <Authorization Module={"HRM"} SubModule={"Pay-Slip"}>
                <PaySlip />
              </Authorization>
            }
          />
          <Route
            exact
            path="/payslip"
            element={
              <Authorization Module={"HRM"} SubModule={"Pay-Slip"}>
                <NewPaySlip />
              </Authorization>
            }
          />

          <Route
            exact
            path="/import-attendance"
            element={
              <Authorization Module={"HRM"} SubModule={"Attendance"}>
                <AttendanceImport />
              </Authorization>
            }
          />
          <Route
            exact
            path="/salary-report"
            element={
              <Authorization Module={"HRM"} SubModule={"Salary Report"}>
                <EmployeeSalaryList />
              </Authorization>
            }
          />
          <Route
            exact
            path="/holidayallowance"
            element={
              <Authorization Module={"HRM"} SubModule={"Salary Report"}>
                <HolidayAllowance />
              </Authorization>
            }
          />
          <Route
            exact
            path="/heldupreport"
            element={
              <Authorization Module={"HRM"} SubModule={"Salary Report"}>
                <HeldupReport />
              </Authorization>
            }
          />
          <Route
            exact
            path="/salarystatement"
            element={
              <Authorization Module={"HRM"} SubModule={"Salary Report"}>
                <SalaryStatement />
              </Authorization>
            }
          />


          <Route
            exact
            path="/attendance-report-short"
            element={
              <Authorization Module={"HRM"} SubModule={"Attendance Report"}>
                <AttendanceReportShort />
              </Authorization>
            }
          />
          <Route
            exact
            path="/dailyattendance-report-short"
            element={
              <Authorization Module={"HRM"} SubModule={"Attendance Report"}>
                <DailyAttendanceReport />
              </Authorization>
            }

          />
          <Route
            exact
            path="/departmentwiseattendance"
            element={
              <Authorization Module={"HRM"} SubModule={"Attendance Report"}>
                <DepartmentWiseAttendanceReport />
              </Authorization>
            }

          />

          <Route
            exact
            path="/latereport"
            element={
              <Authorization Module={"HRM"} SubModule={"Attendance Report"}>
                <LateReport />
              </Authorization>
            }
          />
          <Route
            exact
            path="/presentreport"
            element={
              <Authorization Module={"HRM"} SubModule={"Attendance Report"}>
                <PresentReport />
              </Authorization>
            }
          />
          <Route
            exact
            path="/absentreport"
            element={
              <Authorization Module={"HRM"} SubModule={"Attendance Report"}>
                <AbsentReport />
              </Authorization>
            }
          />



          <Route
            exact
            path="/departmentwisesalaryreport"
            element={
              <Authorization Module={"HRM"} SubModule={"Pay-Slip"}>
                <DepartmentWiseSalaryReport />
              </Authorization>
            }

          />
          <Route
            exact
            path="/overtime-report"
            element={
              <Authorization Module={"HRM"} SubModule={"Pay-Slip"}>
                <OvertimeReport />
              </Authorization>
            }

          />
          <Route
            exact
            path="/departmentwiseovertimreport"
            element={
              <Authorization Module={"HRM"} SubModule={"Pay-Slip"}>
                <DepartmentwiseOvertimeReport />
              </Authorization>
            }
          />
          {/* Tools */}
          <Route
            exact
            path="GroupOfCompany"
            element={
              <Authorization Module={"Settings"} SubModule={"Group Of Company"}>
                <GroupOfCompany />
              </Authorization>
            }
          />
          <Route
            exact
            path="Company"
            element={
              <Authorization Module={"Settings"} SubModule={"Company"}>
                <Company />
              </Authorization>
            }
          />
          <Route
            exact
            path="office"
            element={
              <Authorization Module={"Settings"} SubModule={"Office"}>
                <Office />
              </Authorization>
            }
          />
          <Route
            exact
            path="OfficeType"
            element={
              <Authorization Module={"Settings"} SubModule={"Office Type"}>
                <OfficeType />
              </Authorization>
            }
          />
          <Route
            exact
            path="OfficeLocation"
            element={
              <Authorization Module={"Settings"} SubModule={"Office Location"}>
                <OfficeLocation />
              </Authorization>
            }
          />
          <Route
            exact
            path="warehouse"
            element={
              <Authorization Module={"Settings"} SubModule={"Warehouse"}>
                <Warehouse />
              </Authorization>
            }
          />
          <Route
            exact
            path="outlet"
            element={
              <Authorization Module={"Settings"} SubModule={"Outlet"}>
                <Outlet />
              </Authorization>
            }
          />
          <Route
            exact
            path="businessprofile"
            element={
              <Authorization Module={"Settings"} SubModule={"Business Profile"}>
                <BusinessProfile />
              </Authorization>
            }
          />
          <Route
            exact
            path="contactgroup"
            element={
              <Authorization Module={"Settings"} SubModule={"Contact Groups"}>
                <ContactGroup />
              </Authorization>
            }
          />
          <Route
            exact
            path="deliverygroup"
            element={
              <Authorization Module={"Settings"} SubModule={"Delivery Methods"}>
                <DeliveryGroup />
              </Authorization>
            }
          />
          <Route
            exact
            path="accountgroup"
            element={
              <Authorization Module={"Settings"} SubModule={"Account Groups"}>
                <AccountGroup />
              </Authorization>
            }
          />
          <Route
            exact
            path="permissions"
            element={
              <Authorization Module={"Settings"} SubModule={"Permissions"}>
                <Permissions />
              </Authorization>
            }
          />
        </Route>
        <Route path="/login" element={<LoginForm />}></Route>
        <Route path="/signup" element={<SignupForm />}></Route>
      </Routes>

      {/* <div>
        <SessionResetter />
      </div> */}
    </>
  );
}

export default App;
