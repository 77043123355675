import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { updateEmployeeSalary } from "../../../actions/employeeSalaryActions";
import {
  Form,
  Input,
  Drawer,
  DatePicker,
  TreeSelect,
  Button,
  Col,
  Row,
  Select,
} from "antd";

const { Option } = Select;

const Edit = ({
  details,
  employeeList,
  setUpdatelist,
  updateEmployeeSalary,
  reload,
  setReload
}) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [houseRent, sethouseRent] = useState(0);
  const [monthlySalary, setmonthlySalary] = useState(0);
  const calculatePrice = () => {
    let calculatePrice = 0;
    if (monthlySalary !== null) {
      calculatePrice = ((parseFloat(monthlySalary) * 0.5));
      // calculatePrice = parseFloat(monthlySalary) + parseFloat(discountAmount);
    }
    return calculatePrice;
  };
  useEffect(() => {
    form.setFieldsValue({ houseRent: calculatePrice() });
  }, [monthlySalary, form]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    updateEmployeeSalary(details.id, values).then(e => {
      setReload(!reload)

      setVisible(false);
    });

  };
  console.log(details);

  return (
    <>
      <Button type="primary" onClick={showDrawer} style={{ marginRight: "10px" }}>
        Edit
      </Button>

      <Drawer
        title="Edit Employee Salary"
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} initialValues={details}>
          {/* <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="employee"
                label="Employee"
                rules={[
                  {
                    required: true,
                    message: "Please select A Employee",
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  optionFilterProp="name"
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  treeData={employeeList}
                />
              </Form.Item>
            </Col>
          </Row> */}

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="grossSalary"
                label="Gross Salary"
                rules={[
                  { required: true, message: "Please enter Gross Salary" },
                ]}
              >
                <Input placeholder="Please enter Gross Salary"
                // onChange={(e) => setmonthlySalary(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="bankAccount"
                label="Bank Account"
                rules={[
                  { required: true, message: "Please enter Bank Account" },
                ]}
              >
                <Input placeholder="Please enter Bank Account" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="medAllowance"
                label="Medical"
                rules={[
                  { required: false },
                ]}
              >
                <Input placeholder="Please enter Medical Allowance" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="foodAllowance"
                label="Food"
                rules={[
                  { required: false },
                ]}
              >
                <Input placeholder="Please enter Food Allowance" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="convenience"
                label="Convenience"
                rules={[
                  { required: false },
                ]}
              >
                <Input placeholder="Please enter Convenience" />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="houseRent"
                label="House Rent"
                rules={[
                  { required: true, message: "Please enter House Rent" },
                ]}
              >
                <Input placeholder="Please enter House Rent" initialValue={calculatePrice()} />
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="holidayAllowance"
                label="Holiday Allowance"
                rules={[
                  { required: false, message: "Please enter Holiday Allowance" },
                ]}
              >
                <Input placeholder="Please enter Holiday Allowance" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="attendanceBonus"
                label="Attendance Bonus"
                rules={[
                  { required: false, message: "Please enter Attendance Bonus" },
                ]}
              >
                <Input placeholder="Please enter Attendance Bonus" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeList: state.employee.employeelist,
  };
};
export default connect(mapStateToProps, { updateEmployeeSalary })(Edit);
