import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";
import moment from 'moment';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const Excelldownload = ({ data, from, loading }) => {
  // console.log(data);
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  let fileName = 'Salary Report';

  if (loading) {
    return <Button type="ghost" style={{ width: "100%", marginRight: "10px" }}>....Loading</Button>
  } else
    return (
      <ExcelFile element={<Button type="primary" style={{ width: "100%", marginRight: "10px" }}>Download</Button>} filename={fileName}>
        <ExcelSheet data={data} name='Salary Report'>
          <ExcelColumn label="Department" value="employeeDepartment" />
          <ExcelColumn label="Pin no." value="employeeId" />
          <ExcelColumn label="Name" value="employeeName" />
          <ExcelColumn label="Designation" value="employeeRole" />
          <ExcelColumn label="Basic salary" value="Basicsalary" />
          <ExcelColumn label="House rent" value="houseRent" />
          <ExcelColumn label="Medical Allowance" value="medAllowance" />
          <ExcelColumn label="Convenience" value="convenience" />
          <ExcelColumn label="Food Allowance" value="foodAllowance" />
          <ExcelColumn label="Gross salary" value="grossSalary" />
          <ExcelColumn label="Working days" value="workingdays" />
          <ExcelColumn label="Holidays" value="Holidays" />
          <ExcelColumn label="Leaves" value="nonpaidleave" />

          <ExcelColumn label="Absent" value="absent" />
          <ExcelColumn label="Absent deduction" value="absentpayment" />
          <ExcelColumn label="Overtime" value="totalovertime" />
          <ExcelColumn label="Overtime Payment" value="overtimepayment" />
          <ExcelColumn label="Net salary" value="totalsalary" />
          <ExcelColumn label="Payment Date" value={(col) => (moment(col.paymentDate).format("DD-MM-YYYY"))} />
        </ExcelSheet>
      </ExcelFile>
    );
};

export default Excelldownload;
