import React, { useEffect, useState, useRef } from "react";
import { Button } from "antd";
import ReactExport from "react-export-excel";
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
    name: "Johson",
    amount: 30000,
    sex: "M",
    is_married: true,
  },
  {
    name: "Monika",
    amount: 355000,
    sex: "F",
    is_married: false,
  },
  {
    name: "John",
    amount: 250000,
    sex: "M",
    is_married: false,
  },
  {
    name: "Josef",
    amount: 450500,
    sex: "M",
    is_married: true,
  },
];

var dataSet2 = [
  {
    name: "Johnson",
    total: 25,
    remainig: 16,
  },
  {
    name: "Josef",
    total: 25,
    remainig: 7,
  },
];

const Excelldownload = ({ data, data1, startDate, endDate }) => {
  // console.log(data);
  let fileName = "Department Wise Attendance Report";
  return (
    <ExcelFile
      element={<Button style={{ width: "100%" }}>Download</Button>}
      filename={fileName}
    >
      <ExcelSheet data={data.department} name="Department Wise Attendance Report">
        <ExcelColumn label="Section" value="name" />
        <ExcelColumn label="Total" value="total" />
        <ExcelColumn label="Present" value="present" />
        <ExcelColumn label="Absent" value="absent" />
        <ExcelColumn label="Total Operator" value="TOP" />
        <ExcelColumn label="Present Operator" value="PROP" />
        <ExcelColumn label="Absent Operator" value="ABOP" />
        <ExcelColumn label="Total Assist. Operator" value="TAO" />
        <ExcelColumn label="Present Assist. Operator" value="PRAO" />
        <ExcelColumn label="Absent Assist. Operator" value="ABAO" />
        <ExcelColumn label="Total Ironman" value="TIM" />
        <ExcelColumn label="Present Ironman" value="PRIM" />
        <ExcelColumn label="Absent Ironman" value="ABIM" />
      </ExcelSheet>

    </ExcelFile>
  );
};

export default Excelldownload;
