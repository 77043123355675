import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllEmployeeSalaryP } from "../../../actions/employeeSalaryActions";
import Rendertable from "./Rendertable";

const EmployeeSalaryList = ({
  getAllEmployeeSalaryP,
  AllemployeeSalarylist,
  reload,
  setReload,
  setData,
  loading,
  setloading,
  location,
  Designation,
  Department,
  Keyword,
  flag,
  data,
  pageno,
  page_size,
}) => {
  useEffect(() => {
    setloading(true);
    getAllEmployeeSalaryP(location.current, Designation.current, Department.current, Keyword.current, pageno.current, page_size.current).then((e) => {
      console.log(e)
      setData(e);
      setloading(false);
    });;
  }, [reload]);

  // console.log(AllemployeeSalarylist);

  return (
    <>
      {
        <Rendertable
          loading={loading}
          List={data}
          reload={reload}
          setReload={setReload}
          pageno={pageno}
          page_size={page_size}
          flag={flag}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    AllemployeeSalarylist: state.employeeSalary.employeeSalarylist,
  };
};

export default connect(mapStateToProps, {
  getAllEmployeeSalaryP,
})(EmployeeSalaryList);
