import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllEmployeebyPagination, syncAllEmployeebyPagination, getAllEmployeebyPaginationInactive } from "../../../actions/employeeAction";
import Rendertable from "./Rendertable";
import { getAllLocation } from "../../../actions/warehouseAction";
import { getAllUserRole } from "../../../actions/userRoleAction";
import { Tabs } from "antd";

const EmployeeList = ({
  getAllEmployeebyPagination,
  getAllEmployeebyPaginationInactive,
  employeeList,
  getAllLocation,
  reload,
  setReload,
  setData,
  loading,
  setloading,
  location,
  Designation,
  Department,
  Keyword,
  flag,
  data,
  pageno,
  page_size,
}) => {

  const [activeEmployee, setActiveEmployee] = useState([]);
  const [inactiveEmployee, setInactiveEmployee] = useState([]);
  const [loadingActive, setLoadingActive] = useState(true);
  const [loadingInactive, setLoadingInactive] = useState(true);
  const [activatekey, setActivatekey] = useState(1);

  // useEffect(() => {
  //   setloading(true);
  //   getAllEmployeebyPagination(location.current, Designation.current, Department.current, Keyword.current, pageno.current, page_size.current).then((e) => {
  //     setData(e);
  //   });
  // }, [reload]);
  useEffect(() => {
    setLoadingActive(true);
    setLoadingInactive(true);
    if (activatekey == 1) {

      getAllEmployeebyPagination(location.current, Designation.current, Department.current, Keyword.current, pageno.current, page_size.current)
        .then((e) => {
          setActiveEmployee(e);
          setLoadingActive(false);
        });
    } else {

      getAllEmployeebyPaginationInactive(location.current, Designation.current, Department.current, Keyword.current, pageno.current, page_size.current)
        .then((res) => {
          setInactiveEmployee(res);
          setLoadingInactive(false);
        });
    }
  }, [reload]);

  const onChange = (key) => {
    console.log(key);
    setActivatekey(key)
    pageno.current = 1;
    setReload(!reload)
  };

  // return (
  //   <>
  //     {
  //       <Rendertable
  //         loading={loading}
  //         List={data}
  //         reload={reload}
  //         setReload={setReload}
  //         pageno={pageno}
  //         page_size={page_size}
  //         flag={flag}
  //       />
  //     }
  //   </>
  // );

  return (
    <>
      <Tabs defaultActiveKey="1" style={{ width: "100%" }} onChange={onChange}>
        <Tabs.TabPane tab="Active Employees" key="1">
          <Rendertable
            loading={loadingActive}
            List={activeEmployee}
            reload={reload}
            setReload={setReload}
            pageno={pageno}
            page_size={page_size}
            flag={flag}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Resigned Employees" key="2">
          <Rendertable
            loading={loadingInactive}
            List={inactiveEmployee}
            reload={reload}
            setReload={setReload}
            pageno={pageno}
            page_size={page_size}
            flag={flag}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );

};

const mapStateToProps = (state) => {
  return {
    employeeList: state.employee.employeelistByBranchShift,
  };
};

export default connect(mapStateToProps, {
  getAllEmployeebyPagination,
  getAllEmployeebyPaginationInactive,
  getAllLocation,
  getAllUserRole,
})(EmployeeList);
