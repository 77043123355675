import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSpecificUserEmployeeLeave,
  getAllEmployeeLeave,
} from "../../../actions/employeeLeaveActions";
import {
  getSpecificEmployeeAttendence,
  getAllEmployeeAttendenceOfMonth,
  getAllEmployeeAttendenceOfDateRange,
  getEmployeeAttendenceReport,
  syncAttendenceByDate,
} from "../../../actions/AttendenceAction";
import { getAllEmployeeByBranch } from "../../../actions/employeeAction";
import moment from "moment";

import { Row, Col, Skeleton } from "antd";

const EmployeeAttendanceList = ({
  getEmployeeAttendenceReport,
  syncAttendenceByDate,
  employee,
  location,
  startDate,
  endDate,
  data,
  setData,
  updatelist,
  setUpdatelist,
  componentRef,
}) => {
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setloading(true);
    syncAttendenceByDate(startDate, startDate, "1,2,3,4").then(e => {
      getEmployeeAttendenceReport(startDate, endDate, location).then(e => {
        setData(e)
        setloading(false);
      })
    })
    // getEmployeeAttendenceReport(startDate, endDate, location).then(e => {
    //   setData(e)
    //   setloading(false);
    // })


  }, [updatelist, startDate, endDate, employee, location, setUpdatelist]);



  if (loading) {
    return <Skeleton active />;
  } else {
    return (
      <>
        <div ref={componentRef}>
          <table className="leave_table" style={{ borderBottom: "0px", lineHeight: "14px", }} >
            <tr><td><h3 style={{ fontWeight: 500, fontSize: "22px", paddingTop: "10px" }}>Famous Design LTD.</h3>
              <p>245/1-F,West Agargoan, Sher-E-Bangla nagar,Dhaka-1207.</p>
              <p style={{ fontWeight: 500, fontSize: "20px", textDecoration: "underline" }}>Daily Manpower Statement </p>
              <p>Date : {moment(startDate).format("DD-MM-YYYY")}</p>
            </td></tr>
          </table>

          <table className="leave_table" >

            <tr style={{ fontWeight: 700 }}>
              <td >Section</td>
              <td >Total</td>
              <td >Present</td>
              <td >Absent</td>
              <td >T.OP</td>
              <td >PR.OP</td>
              <td >AB.OP</td>
              <td >T.A/O</td>
              <td >PR.A/O</td>
              <td >AB.A/O</td>
              <td >T.I/M</td>
              <td >PR.I/M</td>
              <td >AB.I/M</td>
            </tr>
            {data.department.map(item => {
              if (item.total > 0)
                return <tr>
                  <td style={{ fontWeight: 500 }}>{item.name}</td>
                  <td style={{ background: "#fed58a" }}>{item.total}</td>
                  <td style={{ background: "#fed58a" }}>{item.present}</td>
                  <td style={{ background: "#fed58a" }}>{item.absent}</td>
                  <td style={{ background: "#b0dcff" }}>{item.TOP}</td>
                  <td style={{ background: "#b0dcff" }}>{item.PROP}</td>
                  <td style={{ background: "#b0dcff" }}>{item.ABOP}</td>
                  <td style={{ background: "#d6edff" }}>{item.TAO}</td>
                  <td style={{ background: "#d6edff" }}>{item.PRAO}</td>
                  <td style={{ background: "#d6edff" }}>{item.ABAO}</td>
                  <td style={{ background: "#e3f3ff" }}>{item.TIM}</td>
                  <td style={{ background: "#e3f3ff" }}>{item.PRIM}</td>
                  <td style={{ background: "#e3f3ff" }}>{item.ABIM}</td>

                </tr>
            })}
            <tr style={{ fontWeight: 700 }}>
              <td>Total</td>
              <td>{data.summary.Ttotal}</td>
              <td>{data.summary.Tpresent}</td>
              <td>{data.summary.Tabsent}</td>
              <td>{data.summary.TTOP}</td>
              <td>{data.summary.TPROP}</td>
              <td>{data.summary.TABOP}</td>
              <td>{data.summary.TTAO}</td>
              <td>{data.summary.TPRAO}</td>
              <td>{data.summary.TABAO}</td>
              <td>{data.summary.TTIM}</td>
              <td>{data.summary.TPRIM}</td>
              <td>{data.summary.TABIM}</td>
            </tr>
            <tr style={{ fontWeight: 700 }}>
              <td>Absent of (%)</td>
              <td colSpan={12}>{data.summary.percentage} %</td>

            </tr>
          </table >
        </div>
      </>
    );
  }
};



export default connect(null, {
  getSpecificUserEmployeeLeave,
  getSpecificEmployeeAttendence,
  getAllEmployeeAttendenceOfMonth,
  getAllEmployeeAttendenceOfDateRange,
  getEmployeeAttendenceReport,
  getAllEmployeeLeave,
  getAllEmployeeByBranch,
  syncAttendenceByDate,
})(EmployeeAttendanceList);
