import React, { useState, useRef } from "react";
import { Button, Menu, Layout } from "antd";
import { Link } from "react-router-dom";
import {
  BarChartOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  ApartmentOutlined,
  ShoppingCartOutlined,
  SelectOutlined,
  DatabaseOutlined,
  SkinOutlined,
  UserOutlined,
  SettingOutlined,
  PieChartOutlined,
  DotChartOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";

const NewSidebar = ({ auth }) => {
  const [collapsed, setCollapsed] = useState(false);
  let collapseWidth = useRef(20);
  let isCollapsed = useRef(false);
  const { Sider } = Layout;
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const PermissionsContain = (substring) => {
    if (auth.isSignedIn) {
      let flag = 0;
      auth.permissions.find((element) => {
        if (element.includes(substring)) {
          flag = 1;
        }
      });
      if (flag == 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // console.log(auth);

  const items = auth.isSignedIn
    ? [
      getItem(
        <Link to="/dashboard">Dashboards</Link>,
        "1",
        <PieChartOutlined />
      ),


      PermissionsContain("HRM")
        ? getItem("HRM", "sub1", <TeamOutlined />, [
          auth.permissions.includes("HRM.Departments_is_read")
            ? getItem(<Link to="/department">Departments</Link>, "5")
            : "",
          auth.permissions.includes("HRM.Designation_is_read")
            ? getItem(<Link to="/user-role">Designation</Link>, "6")
            : "",

        ])
        : "",

      PermissionsContain("HRM")
        ? getItem("Employee", "sub2", <TeamOutlined />, [

          auth.permissions.includes("HRM.Employee_is_read")
            ? getItem(<Link to="/employee">Employee</Link>, "7")
            : "",
          auth.permissions.includes("HRM.Salary Setup_is_read")
            ? getItem(<Link to="/newsalary">New Employee</Link>, "17")
            : "",
          auth.permissions.includes("HRM.Salary Setup_is_read")
            ? getItem(<Link to="/salary">Salary & Promotions</Link>, "8")
            : "",

        ])
        : "",
      PermissionsContain("HRM")
        ? getItem("Leave", "sub3", <TeamOutlined />, [

          auth.permissions.includes("HRM.LeaveType_is_read")
            ? getItem(<Link to="/leave-type">Leave Type</Link>, "9")
            : "",
          auth.permissions.includes("HRM.Employee Leave_is_read")
            ? getItem(
              <Link to="/employee-leave">Employee Leave</Link>,
              "10"
            )
            : "",
        ])
        : "",

      PermissionsContain("HRM")
        ? getItem("Attendance", "sub4", <TeamOutlined />, [
          auth.permissions.includes("HRM.Attendance Report_is_read")
            ? getItem(
              <Link to="/dailyattendance-report-short">
                Daily Attendance Report
              </Link>,
              "18"
            )
            : "",
          auth.permissions.includes("HRM.Attendance Report_is_read")
            ? getItem(
              <Link to="/departmentwiseattendance">
                Department wise Attendance
              </Link>,
              "28"
            )
            : "",

          auth.permissions.includes("HRM.Attendance Report_is_read")
            ? getItem(
              <Link to="/presentreport">
                Present report
              </Link>,
              "26"
            )
            : "",
          auth.permissions.includes("HRM.Attendance Report_is_read")
            ? getItem(
              <Link to="/absentreport">
                Absent report
              </Link>,
              "27"
            )
            : "",
          auth.permissions.includes("HRM.Attendance Report_is_read")
            ? getItem(
              <Link to="/latereport">
                Late report
              </Link>,
              "22"
            )
            : "",


          auth.permissions.includes("HRM.Pay-Slip_is_read")
            ? getItem(
              <Link to="/overtime-report">
                Overtime statement
              </Link>,
              "20"
            )
            : "",
          auth.permissions.includes("HRM.Pay-Slip_is_read")
            ? getItem(
              <Link to="/departmentwiseovertimreport">
                Overtime summary
              </Link>,
              "21"
            )
            : "",

        ])
        : "",

      PermissionsContain("HRM")
        ? getItem("Payslip", "sub5", <TeamOutlined />, [
          auth.permissions.includes("HRM.Pay-Slip_is_read")
            ? getItem(<Link to="/payslip">Pay-Slip</Link>, "15")
            : "",


          auth.permissions.includes("HRM.Salary Report_is_read")
            ? getItem(<Link to="/salary-report">Salary Report</Link>, "16")
            : "",
          auth.permissions.includes("HRM.Pay-Slip_is_read")
            ? getItem(
              <Link to="/departmentwisesalaryreport">
                Salary summary
              </Link>,
              "19"
            )
            : "",
          auth.permissions.includes("HRM.Pay-Slip_is_read")
            ? getItem(
              <Link to="/salarystatement">
                Salary Statement
              </Link>,
              "25"
            )
            : "",
          auth.permissions.includes("HRM.Pay-Slip_is_read")
            ? getItem(
              <Link to="/heldupreport">
                Heldup report
              </Link>,
              "24"
            )
            : "",
          auth.permissions.includes("HRM.Pay-Slip_is_read")
            ? getItem(<Link to="/holidayallowance">Holiday Allowance</Link>, "23")
            : "",

        ])
        : "",


      // PermissionsContain("Settings")
      //   ? getItem("Settings", "sub10", <SettingOutlined />, [
      //     auth.permissions.includes("Settings.Group Of Company_is_read")
      //       ? getItem(
      //         <Link to="/GroupOfCompany">Group Of Company</Link>,
      //         "59"
      //       )
      //       : "",
      //     auth.permissions.includes("Settings.Company_is_read")
      //       ? getItem(<Link to="/Company">Company</Link>, "60")
      //       : "",
      //     auth.permissions.includes("Settings.Office Type_is_read")
      //       ? getItem(<Link to="/OfficeType">Office Type</Link>, "61")
      //       : "",
      //     auth.permissions.includes("Settings.Office Location_is_read")
      //       ? getItem(
      //         <Link to="/OfficeLocation">Office Location</Link>,
      //         "62"
      //       )
      //       : "",
      //     auth.permissions.includes("Settings.Office_is_read")
      //       ? getItem(<Link to="/office">Office</Link>, "63")
      //       : "",
      // auth.permissions.includes("Settings.Warehouse_is_read")
      //  ? getItem(<Link to="/warehouse">Warehouse </Link>, "64")
      //   : "",
      // auth.permissions.includes("Settings.Outlet_is_read")
      //   ? getItem(<Link to="/outlet">Outlet </Link>, "65")
      //   : "",

      // auth.permissions.includes("Settings.Business Profile_is_read")
      //   ? getItem(
      //       <Link to="/businessprofile">Business Profile</Link>,
      //       "66"
      //     )
      //   : "",
      //   auth.permissions.includes("Settings.Contact Groups_is_read")
      //     ? getItem(<Link to="/contactgroup">Contact Groups</Link>, "67")
      //     : "",
      //   auth.permissions.includes("Settings.Delivery Methods_is_read")
      //     ? getItem(
      //       <Link to="/deliverygroup">Delivery Methods</Link>,
      //       "68"
      //     )
      //     : "",
      //   auth.permissions.includes("Settings.Account Groups_is_read")
      //     ? getItem(<Link to="/accountgroup">Account Groups</Link>, "69")
      //     : "",
      //   auth.permissions.includes("Settings.Permissions_is_read")
      //     ? getItem(<Link to="/permissions">Permissions</Link>, "70")
      //     : "",
      // ])
      // : "",
    ]
    : "";
  return (
    <div className="sidebar">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="lg"
      // style={{ height: "100vh" }}
      // onClick={() => {
      //   if (isCollapsed.current) {
      //     collapseWidth.current = 0;
      //     isCollapsed.current = !isCollapsed.current;
      //   } else {
      //     collapseWidth.current = 20;
      //     isCollapsed.current = !isCollapsed.current;
      //   }
      // }}
      // collapsedWidth={collapseWidth.current}
      >
        <div
          className="logo"
          style={{
            backgroundColor: "transparent",
            height: "60px",
            zIndex: "-99",
          }}
        >
          {/* {!collapsed ? (
            <img
            // src={auth.profile.branch.logo}
            // src={`${process.env.PUBLIC_URL}` + `/AutoLogic2.png`}
            // style={{ height: "40px", zIndex: "-1" }}
            src={`${process.env.PUBLIC_URL}` + `/apple-touch-icon.png`}
            style={{ height: "50px", zIndex: "-1", marginLeft: "30px", marginTop: "20px", marginBottom: "30px"}}
            />
          ) : (
            <img
            // src={`${process.env.PUBLIC_URL}` + `/AutoLogic2.png`}
            // src={auth.profile.branch.logo}
            // style={{ height: "40px", zIndex: "-1" }}
            />
          )} */}

          {!collapsed ? (
            <h1 style={{ fontSize: "30px", fontWeight: 800, height: "20px", zIndex: "-1", marginLeft: "30px", marginTop: "20px", marginBottom: "5px", color: "whitesmoke" }}> FD LTD</h1>

          ) : (
            <img
            // src={`${process.env.PUBLIC_URL}` + `/AutoLogic2.png`}
            // src={auth.profile.branch.logo}
            // style={{ height: "40px", zIndex: "-1" }}
            />
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          id="sidebar-nav"
          items={items}
          style={{ "min-height": "80vh" }}
        />
      </Sider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(NewSidebar);
// export default NewSidebar;
