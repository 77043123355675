import React from "react";
import { Table, Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

import EmployeeSalaryDetails from "./EmployeeSalaryDetails";

class Rendertable extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8, marginTop: -140 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (page, page_size) => {
    this.props.pageno.current = page;
    this.props.page_size.current = page_size;

    this.setState({
      current: page,
      minIndex: (page - 1) * this.props.page_size.current,
      maxIndex: page * this.props.page_size.current,
    });
    this.props.setReload(!this.props.reload);
  };

  render() {
    const columns = [
      {
        title: "SL.",
        key: "index",
        render: (text, record, index) => index + 1,
      },
      {
        title: "PIN",
        key: "employee_pin",
        dataIndex: "employee_pin",
        ...this.getColumnSearchProps("employee_pin"),

      },
      {
        title: "Employee",
        dataIndex: "employeeName",
        key: "employeeName",
        // width: "20%",
        ...this.getColumnSearchProps("employeeName"),
      },
      {
        title: "Designation",
        dataIndex: "employeeRole",
        key: "employeeRole",
        // width: "20%",
        ...this.getColumnSearchProps("employeeRole"),
      },
      // {
      //   title: "Department",
      //   dataIndex: "employeeDepartment",
      //   key: "employeeDepartment",
      //   // width: "20%",
      //   ...this.getColumnSearchProps("employeeDepartment"),
      // },
      // {
      //   title: "Area",
      //   dataIndex: "employeeBranch",
      //   key: "employeeBranch",
      //   // width: "20%",
      //   ...this.getColumnSearchProps("employeeBranch"),
      // },
      {
        title: "Gross Salary",
        dataIndex: "grossSalary",
        key: "grossSalary",
      },
      {
        title: "Basic Salary",
        dataIndex: "monthlySalary",
        key: "monthlySalary",
      },
      {
        title: "House Rent",
        dataIndex: "houseRent",
        key: "houseRent",
      },
      {
        title: "Medical Allowance",
        dataIndex: "medAllowance",
        key: "medAllowance",
      },
      {
        title: "Food Allowance",
        dataIndex: "foodAllowance",
        key: "foodAllowance",
      },
      {
        title: "Convenience",
        dataIndex: "convenience",
        key: "convenience",
      },


      {
        title: "Wage/Hour",
        dataIndex: "perHourWageDay",
        key: "perHourWageDay",
      },
      {
        title: "Overtime/Hour",
        dataIndex: "overtime",
        key: "overtime",
      },


      {
        title: "Holiday Allowance",
        dataIndex: "holidayAllowance",
        key: "holidayAllowance",
      },

      {
        title: "Attendance Bonus",
        dataIndex: "attendanceBonus",
        key: "attendanceBonus",
      },

      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: (details) => (
          <EmployeeSalaryDetails
            details={details}
            reload={this.props.reload}
            setReload={this.props.setReload}
            key="x"
          />
        ),
      },
    ];
    // console.log(this.props.List);
    return <Table
      rowKey="id"
      columns={columns}
      dataSource={this.props.List.results}
      size="small"
      pagination={{
        defaultPageSize: this.props.page_size.current,
        current: this.state.current,
        total: this.props.List.count,
        // pageSizeOptions: page_size_opt,
        showSizeChanger: false,
        onChange: this.handleChange,
      }}
    />;
  }
}

export default Rendertable;
