export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const RESET_SIGN_UP = "RESET_SIGN_UP";
export const AUTH_FAILED = "AUTH_FAILED";
export const Active = "Active";

export const CREATE_CONTACT = "CREATE_CONTACT";
export const GET_ALL_CONTACT = "GET_ALL_CONTACT";
export const GET_ALL_History = "GET_ALL_History";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const GET_CONTACT_SEARCH_RESULT = "GET_CONTACT_SEARCH_RESULT";

export const CREATE_PETTY_CASH = "CREATE_PETTY_CASH";
export const GET_ALL_PETTY_CASH = "GET_ALL_PETTY_CASH";
export const DELETE_PETTY_CASH = "DELETE_PETTY_CASH";
export const UPDATE_PETTY_CASH = "UPDATE_PETTY_CASH";
export const GET_PETTY_CASH_SEARCH_RESULT = "GET_PETTY_CASH_SEARCH_RESULT";

export const CREATE_ACCOUNT_PARENT = "CREATE_ACCOUNT_PARENT";
export const GET_ALL_ACCOUNT_PARENT = "GET_ALL_ACCOUNT_PARENT";
export const DELETE_ACCOUNT_PARENT = "DELETE_ACCOUNT_PARENT";
export const UPDATE_ACCOUNT_PARENT = "UPDATE_ACCOUNT_PARENT";

export const CREATE_CONTACT_TYPE = "CREATE_CONTACT_TYPE";
export const GET_ALL_CONTACT_TYPE = "GET_ALL_CONTACT_TYPE";
export const DELETE_CONTACT_TYPE = "DELETE_CONTACT_TYPE";
export const UPDATE_CONTACT_TYPE = "UPDATE_CONTACT_TYPE";

export const CREATE_DELIVERY_TYPE = "CREATE_DELIVERY_TYPE";
export const GET_ALL_DELIVERY_TYPE = "GET_ALL_DELIVERY_TYPE";
export const DELETE_DELIVERY_TYPE = "DELETE_DELIVERY_TYPE";
export const UPDATE_DELIVERY_TYPE = "UPDATE_DELIVERY_TYPE";

export const CREATE_ATTRIBUTE = "CREATE_ATTRIBUTE";
export const GET_ALL_ATTRIBUTE = "GET_ALL_ATTRIBUTE";
export const GET_SPECIFIC_ATTRIBUTE = "GET_SPECIFIC_ATTRIBUTE";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE";

export const CREATE_WAREHOUSE = "CREATE_WAREHOUSE";
export const GET_ALL_WAREHOUSE = "GET_ALL_WAREHOUSE";
export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE";
export const UPDATE_WAREHOUSE = "UPDATE_WAREHOUSE";
export const GET_ALL_OUTLET = "GET_ALL_OUTLET";
export const GET_ALL_LOCATION = "GET_ALL_LOCATION";

export const CREATE_ATTRIBUTE_TERM = "CREATE_ATTRIBUTE_TERM";
export const GET_ALL_ATTRIBUTE_TERM = "GET_ALL_ATTRIBUTE_TERM";
export const DELETE_ATTRIBUTE_TERM = "DELETE_ATTRIBUTE_TERM";
export const UPDATE_ATTRIBUTE_TERM = "UPDATE_ATTRIBUTE_TERM";

export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_ALL_CATEGORY_PRODUCT = "GET_ALL_CATEGORY_PRODUCT";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

export const CREATE_SINGLE_PRODUCT = "CREATE_SINGLE_PRODUCT";
export const GET_ALL_SINGLE_PRODUCT = "GET_ALL_SINGLE_PRODUCT";
export const GET_SPECIFIC_SINGLE_PRODUCT = "GET_SPECIFIC_SINGLE_PRODUCT";
export const DELETE_SINGLE_PRODUCT = "DELETE_SINGLE_PRODUCT";
export const UPDATE_SINGLE_PRODUCT = "UPDATE_SINGLE_PRODUCT";

export const CREATE_SERVICE = "CREATE_SERVICE";
export const GET_ALL_SERVICE = "GET_ALL_SERVICE";
export const GET_SPECIFIC_SERVICE = "GET_SPECIFIC_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";

export const CREATE_PRODUCT_DETAILS = "CREATE_PRODUCT_DETAILS";
export const GET_ALL_PRODUCT_DETAILS = "GET_ALL_PRODUCT_DETAILS";
export const GET_SPECIFIC_PRODUCT_DETAILS = "GET_SPECIFIC_PRODUCT_DETAILS";
export const GET_SPECIFIC_PRODUCT_DETAILS_FROM_STOCK =
  "GET_SPECIFIC_PRODUCT_DETAILS_FROM_STOCK";
export const DELETE_PRODUCT_DETAILS = "DELETE_PRODUCT_DETAILS";
export const UPDATE_PRODUCT_DETAILS = "UPDATE_PRODUCT_DETAILS";

export const REPORT_SALES = "REPORT_SALES";

export const CREATE_GroupOfCompany = "CREATE_GroupOfCompany";
export const GET_ALL_GroupOfCompany = "GET_ALL_GroupOfCompany";
export const DELETE_GroupOfCompany = "DELETE_GroupOfCompany";
export const UPDATE_GroupOfCompany = "UPDATE_GroupOfCompany";

export const CREATE_COMPANY = "CREATE_COMPANY";
export const GET_ALL_COMPANY = "GET_ALL_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";

export const CREATE_OFFICE_TYPE = "CREATE_OFFICE_TYPE";
export const GET_ALL_OFFICE_TYPE = "GET_ALL_OFFICE_TYPE";
export const DELETE_OFFICE_TYPE = "DELETE_OFFICE_TYPE";
export const UPDATE_OFFICE_TYPE = "UPDATE_OFFICE_TYPE";

export const CREATE_OFFICE_LOCATION = "CREATE_OFFICE_LOCATION";
export const GET_ALL_OFFICE_LOCATION = "GET_ALL_OFFICE_LOCATION";
export const DELETE_OFFICE_LOCATION = "DELETE_OFFICE_LOCATION";
export const UPDATE_OFFICE_LOCATION = "UPDATE_OFFICE_LOCATION";

export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";

export const CREATE_USER_ROLE = "CREATE_USER_ROLE";
export const GET_ALL_USER_ROLE = "GET_ALL_USER_ROLE";
export const DELETE_USER_ROLE = "DELETE_USER_ROLE";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";

export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const GET_ALL_EMPLOYEE = "GET_ALL_EMPLOYEE";
export const GET_SPECIFIC_EMPLOYEE = "GET_SPECIFIC_EMPLOYEE";
export const GET_ALL_EMPLOYEE_BY_BRANCH = "GET_ALL_EMPLOYEE_BY_BRANCH";
export const GET_ALL_EMPLOYEE_BY_SHIFT = "GET_ALL_EMPLOYEE_BY_SHIFT";
export const GET_ALL_EMPLOYEE_BY_BRANCH_SHIFT =
  "GET_ALL_EMPLOYEE_BY_BRANCH_SHIFT";
export const GET_ALL_EMPLOYEE_HISTORY = "GET_ALL_EMPLOYEE_HISTORY";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_PROFILE = "UPDATE_EMPLOYEE_PROFILE";
export const GET_EMPLOYEE_SEARCH_RESULT = "GET_EMPLOYEE_SEARCH_RESULT";

export const CREATE_LEAVE_TYPE = "CREATE_LEAVE_TYPE";
export const GET_ALL_LEAVE_TYPE = "GET_ALL_LEAVE_TYPE";
export const DELETE_LEAVE_TYPE = "DELETE_LEAVE_TYPE";
export const UPDATE_LEAVE_TYPE = "UPDATE_LEAVE_TYPE";

export const CREATE_EMPLOYEE_LEAVE = "CREATE_EMPLOYEE_LEAVE";
export const GET_ALL_EMPLOYEE_LEAVE = "GET_ALL_EMPLOYEE_LEAVE";
export const GET_SPECIFIC_EMPLOYEE_LEAVE = "GET_SPECIFIC_EMPLOYEE_LEAVE";
export const DELETE_EMPLOYEE_LEAVE = "DELETE_EMPLOYEE_LEAVE";
export const UPDATE_EMPLOYEE_LEAVE = "UPDATE_EMPLOYEE_LEAVE";

export const CREATE_EMPLOYEE_ATTENDENCE = "CREATE_EMPLOYEE_ATTENDENCE";
export const GET_ALL_EMPLOYEE_ATTENDENCE_OF_DATE =
  "GET_ALL_EMPLOYEE_ATTENDENCE_OF_DATE";
export const GET_ALL_EMPLOYEE_ATTENDENCE_OF_MONTH =
  "GET_ALL_EMPLOYEE_ATTENDENCE_OF_MONTH";
export const GET_SPECIFIC_EMPLOYEE_ATTENDENCE =
  "GET_SPECIFIC_EMPLOYEE_ATTENDENCE";
export const DELETE_EMPLOYEE_ATTENDENCE = "DELETE_EMPLOYEE_ATTENDENCE";
export const UPDATE_EMPLOYEE_ATTENDENCE = "UPDATE_EMPLOYEE_ATTENDENCE";

export const CREATE_EMPLOYEE_SALARY = "CREATE_EMPLOYEE_SALARY";
export const GET_ALL_EMPLOYEE_SALARY = "GET_ALL_EMPLOYEE_SALARY";
export const GET_SPECIFIC_EMPLOYEE_SALARY = "GET_SPECIFIC_EMPLOYEE_SALARY";
export const DELETE_EMPLOYEE_SALARY = "DELETE_EMPLOYEE_SALARY";
export const UPDATE_EMPLOYEE_SALARY = "UPDATE_EMPLOYEE_SALARY";

export const CREATE_BUSINESS_PROFILE = "CREATE_BUSINESS_PROFILE";
export const GET_ALL_BUSINESS_PROFILE = "GET_ALL_BUSINESS_PROFILE";
export const GET_SPECIFIC_BUSINESS_PROFILE = "GET_SPECIFIC_BUSINESS_PROFILE";
export const DELETE_BUSINESS_PROFILE = "DELETE_BUSINESS_PROFILE";
export const UPDATE_BUSINESS_PROFILE = "UPDATE_BUSINESS_PROFILE";

export const CREATE_EMPLOYEE_LOAN = "CREATE_EMPLOYEE_LOAN";
export const GET_ALL_EMPLOYEE_LOAN = "GET_ALL_EMPLOYEE_LOAN";
export const GET_SPECIFIC_EMPLOYEE_LOAN = "GET_SPECIFIC_EMPLOYEE_LOAN";
export const DELETE_EMPLOYEE_LOAN = "DELETE_EMPLOYEE_LOAN";
export const UPDATE_EMPLOYEE_LOAN = "UPDATE_EMPLOYEE_LOAN";

export const CREATE_EMPLOYEE_LOAN_PAYMENT = "CREATE_EMPLOYEE_LOAN_PAYMENT";
export const GET_ALL_EMPLOYEE_LOAN_PAYMENT = "GET_ALL_EMPLOYEE_LOAN_PAYMENT";
export const GET_SPECIFIC_EMPLOYEE_LOAN_PAYMENT =
  "GET_SPECIFIC_EMPLOYEE_LOAN_PAYMENT";
export const DELETE_EMPLOYEE_LOAN_PAYMENT = "DELETE_EMPLOYEE_LOAN_PAYMENT";
export const UPDATE_EMPLOYEE_LOAN_PAYMENT = "UPDATE_EMPLOYEE_LOAN_PAYMENT";

export const CREATE_EMPLOYEE_SALARY_PAYMENT = "CREATE_EMPLOYEE_SALARY_PAYMENT";
export const GET_ALL_EMPLOYEE_SALARY_PAYMENT =
  "GET_ALL_EMPLOYEE_SALARY_PAYMENT";
export const GET_SPECIFIC_EMPLOYEE_SALARY_PAYMENT =
  "GET_SPECIFIC_EMPLOYEE_SALARY_PAYMENT";
export const DELETE_EMPLOYEE_SALARY_PAYMENT = "DELETE_EMPLOYEE_SALARY_PAYMENT";
export const UPDATE_EMPLOYEE_SALARY_PAYMENT = "UPDATE_EMPLOYEE_SALARY_PAYMENT";

export const CREATE_EMPLOYEE_SALARY_PAYSLIP = "CREATE_EMPLOYEE_SALARY_PAYSLIP";
export const GET_ALL_EMPLOYEE_SALARY_PAYSLIP =
  "GET_ALL_EMPLOYEE_SALARY_PAYSLIP";
export const GET_SPECIFIC_EMPLOYEE_SALARY_PAYSLIP =
  "GET_SPECIFIC_EMPLOYEE_SALARY_PAYSLIP";
export const DELETE_EMPLOYEE_SALARY_PAYSLIP = "DELETE_EMPLOYEE_SALARY_PAYSLIP";
export const UPDATE_EMPLOYEE_SALARY_PAYSLIP = "UPDATE_EMPLOYEE_SALARY_PAYSLIP";
//************** NOTIFICATION TYPES *********************/
export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";
export const GET_ALL_UNREAD_NOTIFICATION = "GET_ALL_UNREAD_NOTIFICATION";
export const MARK_ALL_AS_READ_NOTIFICATION = "MARK_ALL_AS_READ_NOTIFICATION";
export const MARK_AS_READ_NOTIFICATION = "MARK_AS_READ_NOTIFICATION";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const GET_ALL_ACCOUNT = "GET_ALL_ACCOUNT";
export const GET_SPECIFIC_ACCOUNT = "GET_SPECIFIC_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
