import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Maincontent from "./Maincontent";

import { Layout, Breadcrumb } from "antd";

const UserRole = () => {
  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>New Employee</Breadcrumb.Item>
        <Breadcrumb.Item>All</Breadcrumb.Item>
      </Breadcrumb>
      <Maincontent />
    </>
  );
};

export default connect()(UserRole);
