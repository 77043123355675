import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Divider, Button, message } from "antd";
import * as XLSX from "xlsx";
import { createBulkEmployee } from "../../../actions/employeeAction";
import moment from "moment";

const Excelimport = ({ createBulkEmployee }) => {
  const [items, setItems] = useState([]);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, {
          raw: false,
        });
        // console.log(data);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setItems(d);
    });
  };
  const dateFormat = "YYYY-MM-DD";
  const format = "h:mm a";
  const format24 = "HH:mm";
  const onConfirm = () => {
    const value = {
      data: items,
    };
    createBulkEmployee(value);
    // message.success("Department Updated");
  };
  return (
    <div>
      <input
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          readExcel(file);
        }}
      />
      <Button type="primary" onClick={onConfirm}>
        Update
      </Button>
      <Divider />
      <table className="table_import">
        <tr className="table_heading">
          <th scope="col">Employee PIN</th>
          <th scope="col">name</th>
          <th scope="col">Department</th>
          <th scope="col">Designation</th>
          <th scope="col">Area</th>
          <th scope="col">Joining date</th>
          <th scope="col">Gross salary</th>
          <th scope="col">Bank account</th>
        </tr>

        {items.map((e) => {
          return (
            <tr>
              <td>{e.employee_pin}</td>
              <td>{e.name}</td>
              <td>{e.Designation}</td>
              <td>{e.Department}</td>
              <td>{e.Office}</td>
              <td>{e.joining_date}</td>
              <td>{e.bankAccount}</td>
              <td>{e.grossSalary}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default connect(null, { createBulkEmployee })(Excelimport);
