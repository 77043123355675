import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Divider, Button, message } from "antd";
import * as XLSX from "xlsx";
import { createDepartment } from "../../../actions/departmentActions";
import moment from "moment";

const Excelimport = ({ createDepartment }) => {
  const [items, setItems] = useState([]);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, {
          raw: false,
        });
        // console.log(data);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setItems(d);
    });
  };
  const dateFormat = "YYYY-MM-DD";
  const format = "h:mm a";
  const format24 = "HH:mm";
  const onConfirm = () => {
    items.forEach((data) => {
    //   let date = String(data.date);
    //   // console.log(date);
    //   date = new Date(date);
    //   // console.log(date);
    //   date = moment(date).format(dateFormat);
      const value = {
        // id: data.department,
        code:data.code,
        name:data.name,
      };
      // console.log(value['attendanceDate']);
      // console.log(value['id']);
      // console.log(value['name']);
      // console.log(value['isAttended']);
      // console.log(value);

      createDepartment(value);
    });
    message.success("Department Updated");
  };
  return (
    <div>
      <input
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          readExcel(file);
        }}
      />
      <Button type="primary" onClick={onConfirm}>
        Update
      </Button>
      <Divider />
      <table className="table_import">
        <tr className="table_heading">
          <th scope="col">Department Code</th>
          <th scope="col">Department Name</th>
        </tr>

        {items.map((e) => {
          return (
            <tr>
              <td>{e.code}</td>
              <td>{e.name}</td>

            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default connect(null, { createDepartment })(Excelimport);
